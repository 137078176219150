import './home.scss'
import texes1 from '../../assets/images/taxes-1.png'
import texes2 from '../../assets/images/taxes-2.png'
import texes3 from '../../assets/images/taxes-3.png'
import eating from '../../assets/images/Eating.svg'
import background0 from '../../assets/images/background0.png'
import bgbg from '../../assets/images/bg-bg.png'
import pie from '../../assets/images/pie-3.svg'
import sheep from '../../assets/images/Sheep1.svg'
import UserChangeables from '../../UserChangeables.js'
import React, { useCallback, useState } from 'react';


function CopyFunction(element, textToCopy) {
    const [copyStatus, setCopyStatus] = useState('');

    const handleCopy = useCallback(() => {
        if (!navigator.clipboard) {
            setCopyStatus('Clipboard API not supported');
            return;
        }

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setCopyStatus('Copied!');
                setTimeout(() => setCopyStatus(''), 1000); // Clear status after N seconds
            })
            .catch((err) => {
                setCopyStatus('Failed to copy');
                console.error('Could not copy text: ', err);
            });
    }, [textToCopy]);

    return React.cloneElement(element, {
        onClick: handleCopy,
        children: copyStatus || element.props.children
    });
}

const Home = () => {

    return (

        <div id='bgImg' className='bg-[#94d5ff] relative overflow-hidden background-wrap'>

            <div className="clouds-bg" />
            <div class="x1 absolute top-0 -z-10">
                <div class="cloud" />
            </div>
            <div className='topHeader relative'>
                {/* <img src={bgImage} className='w-full relative' /> */}
                <div className='container mx-auto px-4 lg:pt-28 md:pt-20 sm:pt-16 pt-10'>
                    <h1 className='lg:text-[54px] md:text-[40px] sm:text-[30px] text-[36px] lg:mt-[-50px] md:mt-[-50px] sm:mt-[-35px] font-semibold lg:leading-[75px] md:leading-[60px] sm:leading-[50px] leading-[40px] max-w-[1148px] mx-auto text-center text-white '>
                        Welcome to the world of $PUCKA delight.
                        Where sheep and crypto unite.</h1>

                    <p className='lg:mt-[360px] md:mt-[250px] sm:mt-[200px] lg:text-[38px] md:text-[30px] sm:text-[27px] text-[24px] font-semibold lg:leading-[45px] md:leading-[38px] sm:leading-[32px] leading-[28px] max-w-[595px] mx-auto text-center text-white'>
                        Join us in this digital quest. <br />
                        With $PUCKA, you're truly blessed.
                    </p>
                </div>
                <div class="x2 relative -z-10">
                    <div class="cloud" />
                </div>
            </div>
            <div class="x2">
                <div class="cloud" />
            </div>
            <div className='py-10'>
                <div className='container mx-auto px-4'>
                    {CopyFunction(
                        <p className='lg:text-[38px] md:text-[30px] sm:text-[27px] text-[24px] font-semibold lg:leading-[45px] md:leading-[38px] sm:leading-[32px] leading-[28px] max-w-[692px] w-full mx-auto text-center text-white'>
                            CA ~ <span className='relative z-10' style={{ filter: 'blur(3px)' }}>{UserChangeables.TextToCopy} <span className='w-full h-full absolute z-20 bg-[rgba(255,255,255,0.1)] left-0' /></span>
                        </p>,
                       `${UserChangeables.TextToCopy}`
                    )}
                </div>
            </div>
            <div class="x3">
                <div class="cloud" />
            </div>
            <div className='py-10'>
                <div className='container mx-auto px-4 flex flex-wrap gap-4 items-center justify-center'>

                    {UserChangeables.ContactLinks.map(function (jsonObject, i) {
                        const siteName = jsonObject['name'];
                        const siteLink = jsonObject['link'];
                        return <a href={siteLink}><button id='contactButton' className='md:text-xl text-base font-semibold text-black bg-white border border-black rounded-xl py-1 px-1' key={i}>{siteName}</button>  </a>
                            ;
                    })}

                </div>
            </div>
            <div class="x4">
                <div class="cloud" />
            </div>
            <div className='py-10'>
                <div className='container mx-auto px-4'>
                    <ul className='list-none my-0 p-0 max-w-[728px] mx-auto flex flex-col items-center justify-center'>
                        <li className='flex items-center justify-center lg:text-[38px] md:text-[30px] sm:text-[27px] text-[24px] font-semibold lg:leading-[45px] md:leading-[38px] sm:leading-[32px] leading-[28px] text-white'>
                            <img src={texes1} className='w-[10%]' />
                            <span>Taxes: 0% final taxes</span>
                        </li>
                        <li className='flex items-center justify-center lg:text-[38px] md:text-[30px] sm:text-[27px] text-[24px] font-semibold lg:leading-[45px] md:leading-[38px] sm:leading-[32px] leading-[28px] text-white'>
                            <img src={texes2} className='w-[10%]' />
                            <span>Liquidity: Locked!</span>
                        </li>
                        <li className='flex items-center justify-center lg:text-[38px] md:text-[30px] sm:text-[27px] text-[24px] font-semibold lg:leading-[45px] md:leading-[38px] sm:leading-[32px] leading-[28px] text-white'>
                            <img src={texes3} className='w-[10%]' />
                            <span>Dev Supply : Slow Burn on milestones</span>
                        </li>
                    </ul>


                </div>
            </div>
            <div class="x5">
                <div class="cloud" />
            </div>
            <div className=''>
                <div className='container mx-auto px-4 flex flex-wrap gap-4 lg:pl-24 md:pl-8 pl-0'>
                    <img src={eating} className='w-[389px] h-[395px] object-contain' />
                    <p className='pt-8 ml-[-12rem] flex flex-col lg:text-[38px] md:text-[30px] sm:text-[27px] text-[24px] font-semibold lg:leading-[45px] md:leading-[38px] sm:leading-[32px] leading-[28px] text-white'>
                        <span className=''>Supply</span>
                        <span className='font-normal'>1,000,000,000</span>
                    </p>
                </div>
            </div>
            <div class="x2">
                <div class="cloud" />
            </div>
            <div className='flex items-center justify-center'>
                <img src={pie} className='w-[591px] h-[440px] object-contain' />
            </div>
            <div class="x3">
                <div class="cloud" />
            </div>
            <div className=''>
                <div className='container mx-auto px-4 flex flex-col md:flex-row gap-4 items-center'>
                    <h3 className='max-w-[630px] w-full lg:text-[38px] md:text-[30px] sm:text-[27px] text-[24px] font-semibold lg:leading-[45px] md:leading-[38px] sm:leading-[32px] leading-[28px] text-white'>
                        In Sheepland, fun's never bland,
                        Adventure awaits, hand in hand,
                        So pack your bags, let’s take a stand,
                        With $PUCKA, life is simply grand.

                    </h3>
                    <img src={sheep} className='w-[460px] h-[350px] object-contain' />

                </div>
            </div>
            <div class="x2">
                <div class="cloud" />
            </div>
            <div className='py-10'>
                <div className='container mx-auto px-4'>
                    <h5 className='mx-auto text-center max-w-[960px] w-full lg:text-[24px] md:text-[22px] sm:text-[20px] text-[18px] font-semibold lg:leading-[29px] md:leading-[26px] sm:leading-[24px] leading-[22px] text-white'>
                        Legal Disclaimer: $PUCKA is a meme coin with no intrinsic value or expectation of financial return. $PUCKA is completely useless and for entertainment purposes only. When you purchase $PUCKA, you are agreeing that you have seen this disclaimer.
                    </h5>

                </div>
            </div>
            <div class="x2">
                <div class="cloud" />
            </div>
            <div className='pb-8'>
                <div className='container mx-auto px-4'>
                    <h5 className='text-center lg:text-[24px] md:text-[22px] sm:text-[20px] text-[18px] font-semibold lg:leading-[29px] md:leading-[26px] sm:leading-[24px] leading-[22px] text-white'>
                        © PUCKA 2024 - All Rights Reserved.
                    </h5>

                </div>
            </div>
            <div class="x3">
                <div class="cloud" />
            </div>
        </div>
    )
}

export default Home
