// Change the text to your actual text that you want to copy :)
const TextToCopy = "ajhdhhsdhshdgedishehsjhjfjshruyse"

// Change the links to your actual contact links :)
const ContactLinks = [
  {
    name: 'Twitter (X)',
    link: 'https://twitter.com/pucka',
  },
  {
    name: 'Telegram',
    link: 'https://t.me/puckaportal',
  },
  {
    name: 'Dexsceener',
    link: 'https://dexscreener.com/',
  },
];

const UserChangeables = { ContactLinks: ContactLinks, TextToCopy: TextToCopy }

export default UserChangeables;


