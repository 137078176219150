import './App.css';
import './assets/fonts/fredoka/fonts.css'
import Home from './pages/front';

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
